import * as yup from "yup";

const nameValidation = yup
    .string()
    .test(
      "username-special-characters",
      "Name must contain letters.",
      (value) => {
        if (!value) return true;
        const username = value.split("@")[0];
        return /^[a-zA-Z]+$/i.test(username);
      }
    )
    .min(2, "Name must be at least 2 characters.")
    .max(255, "Name must be at most 255 characters.")
    .required("Name is required *")
    .trim(),
  emailValidation = yup
    .string()
    .nullable()
    .email("Enter a valid email")
    .required("Email is required *")
    .test(
      "trimmed-email",
      "Email must not have spaces at the start or end.",
      (value) => value === value?.trim()
    )
    .test(
      "username-special-characters",
      "Email username can only contain letters, numbers, and special characters: +-_.",
      (value) => {
        if (!value) return true;
        const username = value.split("@")[0];
        return /^[a-zA-Z0-9+_.-]+$/i.test(username);
      }
    )
    .test(
      "username-length",
      "Email username must be between 2 and 64 characters.",
      (value) => {
        if (!value) return true;
        const username = value.split("@")[0];
        return username.length >= 2 && username.length <= 64;
      }
    )
    .test(
      "max-two-domain-levels",
      "Email can have at most two domain levels.",
      (value) => {
        if (!value) return true;
        const domain = value.split("@")[1];
        const domainParts = domain?.split(".");
        return domainParts && domainParts.length <= 3;
      }
    )
    .max(40, "Email should be less than 40 characters.")
    .trim(),
  mobileValidation = yup
    .string()
    .required("Mobile Number is required *")
    .trim(),
  departmentValidation = yup
    .string()
    .max(30, "Department must be less than 30 characters")
    .required("Department is required *")
    .trim(),
  roleValidation = yup.string().required("Role is required *").trim(),
  designationValidation = yup
    .string()
    .max(30, "Designation must be less than 30 characters")
    .required("designation is required *")
    .trim(),
  locationValidation = yup.string().required("Location is required *").trim(),
  workValidation = yup.string().required("Work Mode is required *").trim();

const CommonValidation = {
  nameValidation,
  emailValidation,
  mobileValidation,
  departmentValidation,
  roleValidation,
  designationValidation,
  locationValidation,
  workValidation,
};

export default CommonValidation;
